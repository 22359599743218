
.quotes-con{
    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}

  .hr-lines{
    height: 2px;
    width: 33.5px;
    background: #ffffff;
    align-self: center;
  }

  .bg-hero-emp-details {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom !important;
}

.list-subItems-container{
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
}

@media only screen and (max-width: 576px) {
    .details-container {
        padding-top: 68px; 
        padding-bottom:70px;
       
    }
  
    .employee-image{
        background-size:cover;
        border-radius: 50%;
        width: 310px;
        height: 310px;
        background-position: bottom;
       justify-self: center;
       margin-bottom: 40px;
    }
    .grid-display{
        display: grid;
       }
       .autor-con{
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        margin-top: 24px;
        margin-left: 70px;
    }
}

@media only screen and (min-width: 576px) {
    .details-container {
        padding-top: 68px; 
        padding-bottom:70px;
       
    }
  
    .employee-image{
        background-size:cover;
        border-radius: 50%;
        width: 310px;
        height: 310px;
        background-position: bottom;
       justify-self: center;
       margin-bottom: 40px;
    }
    
    .grid-display{
        display: grid;
       }
       .autor-con{
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        margin-top: 24px;
        margin-left: 70px;
    }
}

@media only screen and (min-width: 768px) {
    .details-container {
        padding-top: 120px; 
        padding-bottom:64px;
        
    }
    .employee-image{
        background-size:cover;
        border-radius: 50%;
        width: 304px;
        height: 304px;
        background-position: bottom;
       
    }
   
    .grid-display{
        display: flex;
       }
       .autor-con{
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        margin-top: 24px;
        margin-left: 75px;
    }
}

@media only screen and (min-width: 992px) {
    .details-container {
        padding-top: 120px; 
        padding-bottom:64px;
        
    }
    .employee-image{
        background-size:cover;
        border-radius: 50%;
        width: 360px;
        height: 360px;
        background-position: bottom;
       
    }
   
    .grid-display{
        display: flex;
       }

    .autor-con{
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        margin-top: 24px;
        margin-left: 80px;
    }
}

@media only screen and (min-width: 1200px) {
    .details-container {
        padding-top: 120px; 
        padding-bottom:64px;
        
    }
    .employee-image{
        background-size:cover;
        border-radius: 50%;
        width: 467px;
        height: 467px;
        background-position: bottom;
       
    }
    
    .grid-display{
        display: flex;
       }
       
    .autor-con{
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        margin-top: 24px;
        margin-left: 80px;
    }
}

@media only screen and (min-width: 1400px) {
    .details-container {
        padding-top: 120px; 
        padding-bottom:64px;
       
    }
    .employee-image{
        background-size:cover;
        border-radius: 50%;
        width: 528px;
        height: 528px;
        background-position: bottom;
       
    }
 
   .grid-display{
    display: flex;
   }
   .autor-con{
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    margin-top: 24px;
    margin-left: 80px;
}
}