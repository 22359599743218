
  // Dropdown menu
  
.dropdown-menu {
  --#{$prefix}dropdown-link-font-weight: #{$dropdown-font-weight};

  box-shadow: $dropdown-box-shadow;
  li:hover > .dropdown-item,
  .dropdown-item.show {
    color: var(--#{$prefix}dark);
    font-weight: var(--#{$prefix}font-weight-normal);

  }
  .active > .dropdown-item {
    color: var(--#{$prefix}gray-800);
  }
  .active > .dropdown-item,
  .dropdown-item.active {
    pointer-events: none;
    font-weight: $font-weight-semibold;
  }

  &.w-100 { min-width: 100%; }
}

.dropdown-item {
  font-weight: var(--#{$prefix}font-weight-normal);
  [class^='opacity-'], [class*=' opacity-'] {
    transition: opacity .2s ease-in-out;
  }
  &:hover{
    color:var(--#{$prefix}gray-600) !important;
  }

}

li:hover > .dropdown-item *,
li.active > .dropdown-item * {
  opacity: 1 !important;
}
