p {
    word-break: break-word;
}
p.h6 {
    margin-bottom: 12px;
}

.display-1 {
    font-size: map-get($display-font-sizes, 10);
    -webkit-hyphens: auto; 
    hyphens: auto;
    line-height: $headings-line-height;
    span{
        font-weight: map-get($font-weights, 4);
    }
}

.display-2 {
    -webkit-hyphens: auto; 
    hyphens: auto;
}


.display-3 {
    font-size: map-get($display-font-sizes, 3);
    font-weight: map-get($font-weights, 3);
    -webkit-hyphens: auto; 
    hyphens: auto;
}

.display-4 {
    font-size: map-get($display-font-sizes, 9);
    font-weight: map-get($font-weights, 3);
    -webkit-hyphens: auto; 
    hyphens: auto;
    text-transform: uppercase;
}

.display-5 {
    -webkit-hyphens: auto; 
    hyphens: auto;
}

.display-6 {
    -webkit-hyphens: auto; 
    hyphens: auto;
}


.normal {
    font-size: map-get($display-font-sizes, 8) !important;
    font-weight: map-get($font-weights, 7) !important;
}

@media only screen and (max-width: 576px) {
    .display-3 {
        font-size: map-get($display-font-sizes, 7);
        font-weight: map-get($font-weights, 3);
    }

     .display-4 {
        font-size: map-get($display-font-sizes, 7);
    }
    .display-2 {
        font-size: calc(1.575rem + 1.9vw);
    }
}

@media only screen and (min-width: 576px) {
    .display-3 {
        font-size: map-get($display-font-sizes, 6);
        font-weight: map-get($font-weights, 3);
    }
    
}

@media only screen and (min-width: 768px) {
    .display-3 {
        font-size: map-get($display-font-sizes, 3);
        font-weight: map-get($font-weights, 3);
    }  
    .display-1 {
        font-size: map-get($display-font-sizes, 1);
    }
    
}
@media (max-width: 992px) {
    .colorClass {
        color: var(--#{$prefix}gray-800)!important;
    }
  }


