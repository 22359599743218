.ult-new-ib {
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 0.5s ease;
 }
 
 .ult-new-ib:hover {
    -moz-filter: grayscale(0%);
    filter: none !important;
    transition: all 0.5s ease;
 }