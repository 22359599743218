//
// Masonry grid
// based on https://vestride.github.io/Shuffle/
// --------------------------------------------------


// Base styles

.badge-border {
  border: 1px solid var(--grayscale-300, #E3E9EF)
}

.card-footer-border {
  border-top: 1px solid #e3e9ef;
}

.masonry-grid[data-columns] {
    display: flex;
	flex-flow: column wrap;
    overflow: hidden;
    margin: {
      right: -($grid-gutter-width * .5);
      left: -($grid-gutter-width * .5);
    }
  
    @include clearfix;
  
    .masonry-grid-item {
      width: 100%;
      float: left;
      padding: {
        right: $grid-gutter-width * .5;
        left: $grid-gutter-width * .5;
        bottom: $grid-gutter-width;
      }
    }
  }
  
  .masonry-grid[data-columns='1'] .masonry-grid-item {
    width: 100%;
  }
  
  @include media-breakpoint-up(sm) {
  
    .masonry-grid[data-columns='2'],
    .masonry-grid[data-columns='3'],
    .masonry-grid[data-columns='4'],
    .masonry-grid[data-columns='5'],
    .masonry-grid[data-columns='6'] {
      .masonry-grid-item { width: 50%; }
    }
  }
  @include media-breakpoint-up(md) {
  
    .masonry-grid[data-columns='4'],
    .masonry-grid[data-columns='5'],
    .masonry-grid[data-columns='6'] {
      .masonry-grid-item { width: 33.333%; }
    }
  }
  @include media-breakpoint-up(lg) {
 
    .masonry-grid[data-columns='3'],
    .masonry-grid[data-columns='4'],
    .masonry-grid[data-columns='5'],
    .masonry-grid[data-columns='6'] {
      .masonry-grid-item { width: 33.333%; }
    }
  }
  @include media-breakpoint-up(xl) {
    .masonry-grid[data-columns='4'],
    .masonry-grid[data-columns='5'],
    .masonry-grid[data-columns='6'] {
      .masonry-grid-item { width: 25%; }
    }
  }
  @include media-breakpoint-up(xxl) {
    .masonry-grid[data-columns='5'] {
      .masonry-grid-item { width: 20%; }
    }
    .masonry-grid[data-columns='6'] {
      .masonry-grid-item { width: 16.666%; }
    }
  }

  .gallery-mansory-con {
  height:110vh 
  }

  @media only screen and (max-width: 576px) {
    .mansory-con{
        height: 100%;
    }
}

@media only screen and (min-width: 576px) {
    .mansory-con{
        height: 150vh;
    }
   
}

@media only screen and (min-width: 768px) {
    .mansory-con{
        height: 150vh;
    }
}

@media only screen and (min-width: 992px) {
    .mansory-con{
        height: 100vh;
    }
}

@media only screen and (min-width: 1200px) {
    .mansory-con{
        height: 100vh;
    }
}

@media only screen and (min-width: 1400px) {
    .mansory-con{
        height: 100vh;
    }
   
}


.masonry {
  columns: 3;
  column-gap: 18px;
  @media (max-width: 1200px) {
    columns: 3;
  }
  @media (max-width: 992px) {
    columns: 2;
  }
  @media (max-width: 576px) {
    columns: 1;
  }
  //@media (max-width: 768px) {columns: 1;}
  .grid {
    margin-bottom: 16px;
    position: relative;
    &:before {
      border-radius: 16px;
      content:'';
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
    }
    img {
      width: 100%;
      border-radius: 16px;
    }
  }
}
.mt-auto {
  margin-top:auto;
}