.mb-custom p {
    margin-bottom: 8px;
}

.mb-custom-1 p {
    margin-bottom: 0px;
}

.bg-hero-text-container {
    background-size:contain;
    background-repeat: no-repeat;
    
}
.bg-hero {
    background-size:cover;
    background-repeat: no-repeat;
    background-position: bottom !important;
    height: 80vh;
}

.btn-container-section6 {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    align-self:start;
}

.section3-title-spacing {
    margin-bottom: 67px
}

.section7-title-spacing {
    margin-bottom: 7px
}

.bg-image-container {
    border-radius: 35px;
    background-size: cover;
    padding: 0 12px;
    width: -webkit-fill-available;
    aspect-ratio: 1/1;
}
.separator-middlefield{
    min-height: 284px;
}
.separator-middlefield-content{ 
    padding: 0px 360px;
}

.ele-gap-space {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    align-self:center;
}



.footer-default{
    text-decoration: none;
    color: white;
}
.footer-default:hover{
    color: var(--#{$prefix}jastechCustomGreenHover) !important;
}
.footer-service{
    text-decoration: none;
    color: white;
}
.footer-service:hover{
    color: var(--#{$prefix}gray-400) !important;
}
p >*{
    -webkit-hyphens: none !important;
    -ms-hyphens: none !important;
    hyphens: none !important;
}
.topImageSeparator{
    background-size:cover; 
    background-repeat:repeat-x; 
    background-position: 30% bottom; 
    height:15vw;
    max-height: 600px;
}
.spacinTiny{
    margin-top:16px;
}
.platform-box-mb{
    margin-bottom: 200px;
}
.error-mail{
    text-decoration: none;
    color: var(--#{$prefix}gray-700) !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}
.error-mail:hover{
    color:var(--#{$prefix}gray-600) !important;
}

.error-text > p.lead{
    margin-bottom: 16px;
}
.error-text > p.fs-lg{
    margin-bottom: 12px;
}
@media only screen and (max-width: 576px) {
    .bg-image-container {
        aspect-ratio: 1/1;
    }

    .box-img-pos {
        justify-self: center;
    }
    .bg-hero-text-container {
       margin-left: 13px;
    }
    .new-cards-com {
        padding-left: 0px ;
        padding-right: 0px;
    }
    .topImageSeparator{
        background-size:100%; 
        background-size:300%;
        height:300px;
    }
    .seperator-con {
        padding-left: 12px;
        padding-right: 12px;
    }
    .spacingSmall{
        margin-top: 32px;
    }
    .spacingMedium{
        margin-top: 64px;
    }
    .spacingLarge{
        margin-top: 80px;
    }
    .error-text > p.display-1{
        margin-bottom: 0px;
    }
    .background-pos{
        width: 90%;
    }
    
}

@media only screen and (min-width: 576px) {
    .bg-image-container {
        aspect-ratio: 1/1;
    }
    
    .box-img-pos {
        justify-self: center;
    }
    .topImageSeparator{
        background-size:cover; 
        height:15vw;
        max-height: 600px;
    }
    .spacingSmall{
        margin-top: 48px;
    }
    .spacingMedium{
        margin-top: 80px;
    }
    .spacingLarge{
        margin-top: 120px;
    }
    .error-text > p.display-1{
        margin-bottom: 8px;
    }
    .background-pos{
        width: 90%;
    }
}

@media only screen and (min-width: 768px) {
    .bg-image-container {
        aspect-ratio: 3/4;
        height: 100%;
    }
    .box-img-pos {
        justify-self: center;
    }
    .platform-box-mb{
        margin-bottom: 140px;
    }
    .error-text > p.display-1{
        margin-bottom: 24px;
    }
    .background-pos{
        width: 100%;
    }
}

@media only screen and (min-width: 992px) {
    .bg-image-container {
        aspect-ratio: 3/4;
    }
    .box-img-pos {
        justify-self: left;
    }
    .spacingSmall{
        margin-top: 60px;
    }
    .spacingMedium{
        margin-top: 100px;
    }
    .spacingLarge{
        margin-top: 160px;
    }
}

@media only screen and (min-width: 1200px) {
    .bg-image-container {
        aspect-ratio: 3/4;
        height: 100%
    }
}

@media only screen and (min-width: 1400px) {
    .bg-image-container {
        aspect-ratio: 3/4;
        height: 100%
    }
}