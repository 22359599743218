// Override solid buttons

.btn-primary {
  background: $jastechCustomGreen;
  color: #000 !important;
}

.btn-primary:hover {
  background: $jastechCustomGreenHover;
}


.btn-border-custom {
  border: 1px solid $btn-border-color-custom;
}

.btn-primary-exlusive {
  background: white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: $primary
}

.btn-primary-exlusive:hover {
  color: $primary;
  background: white;
  border-color: var(--ar-btn-hover-border-color);
}


.btn-outline-primary-custom {
  color:var(--#{$prefix}primary);
  pointer-events: none;
  background-color: var(--ar-btn-disabled-bg);
  border-color:var(--#{$prefix}primary);
  opacity: inherit;
  padding: 12px 24px;
}

.btn-outline-black-custom {
  color:var(--#{$prefix}darkBlack);
  pointer-events: none;
  background-color: var(--ar-btn-disabled-bg);
  border-color:var(--#{$prefix}darkBlack);
  opacity: inherit;
  padding: 12px 24px;
}

.btn-outline-dark-custom {
  color:var(--#{$prefix}dark);
  pointer-events: none;
  background-color: var(--ar-btn-disabled-bg);
  border-color:var(--#{$prefix}dark);
  opacity: inherit;
}


 .btn-outline-portfolio {
  color:var(--#{$prefix}gray-800);
  background-color: var(--ar-btn-disabled-bg);
  --ar-btn-padding-x: calc(13px - 1px);
  --ar-btn-padding-y: calc(7px - 1px);
  border: 1px solid $gray-800;
  font-weight:600;
}
 .btn-outline-portfolio:hover {
  color:var(--#{$prefix}dark);
  border-color:var(--#{$prefix}dark);
  border: 2px solid $gray-800;
  --ar-btn-padding-x: calc(13px - 2px);
  --ar-btn-padding-y: calc(7px - 2px);
 }

 .btn-outline-filter-portfolio {
  z-index: 1000;
  color :$gray-100;
  background-color: var(--ar-btn-disabled-bg);
  --ar-btn-padding-x: calc(13px - 1px);
  --ar-btn-padding-y: calc(7px - 1px);
  border: 1px solid $gray-100;
  text-shadow: 0 $gray-100,0 0 $gray-100,0 0 $gray-100,0 0 $gray-100;
  transition: text-shadow 0.2s ease-in-out;
  font-weight:$font-weight-semibold;
}


.btn-outline-filter-portfolio:hover {
  border: 2px solid $gray-100;
  --ar-btn-padding-x: calc(13px - 2px);
  --ar-btn-padding-y: calc(7px - 2px);
  text-shadow: 0 0.015em $gray-100,0 -0.015em $gray-100,0.01em 0 $gray-100,-0.01em 0 $gray-100;
 }

 .btn-outline-filter-activate {
  border: 2px solid $gray-100;
  --ar-btn-padding-x: calc(13px - 2px);
  --ar-btn-padding-y: calc(7px - 2px);
  text-shadow: 0 0.015em $gray-100,0 -0.015em $gray-100,0.01em 0 $gray-100,-0.01em 0 $gray-100;
 }

.btn-outline-platform-custom {
  color:var(--#{$prefix}dark);
  background-color: var(--ar-btn-disabled-bg);
  border-color:var(--#{$prefix}dark);
  opacity: inherit;
}

.btn-outline-platform-custom:hover{
  color:var(--#{$prefix}dark);
  background-color: var(--ar-btn-disabled-bg);
  border-color:var(--#{$prefix}dark);
  opacity: inherit;
}

.btn-jastech-exlusive {
  background: $gray-800;
  
}

.btn-jastech-exlusive:hover {
  background: $gray-900;
  color:$white
}

.btn-jastech-exlusive-white {
  background: $white;
  color:$gray-900
}

.btn-jastech-exlusive-white:hover {
  background: $gray-200;
  color:$gray-900 ;
}

.btn-carousel{
  background-color: var(--#{$prefix}gray-800);
}
.btn-carousel:hover{
  background-color: var(--#{$prefix}gray-900);
  color: var(--#{$prefix}white);
}

