.nav-item-custom {
    color: $dark;
    text-decoration: none;
    align-self: center;
    font-weight: $font-weight-normal;
    transition: color .2s ease-in-out,
    background-color .2s ease-in-out,
    border-color .2s ease-in-out;
}

.nav-item-custom.active {
    color: $dark;
    border-radius: 16px;
    border: 1px solid $dark;
    font-weight: $font-weight-semibold;
}

.nav-item-custom:not(.active):hover {
    color: $dark;
    text-decoration: none;
    align-self: center;
    font-weight: $font-weight-normal;
}

.nav-items-box {
    display: flex;
    padding: 12px 28px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.fade img{
    transition: opacity .35s linear;
}
  /* Translation effect */
  .tab-content .tab-pane img {
    transform: translateY(40px);
    transition: transform 0.6s ease-in-out;
  }
  
  .tab-content .tab-pane.fade.show img {
    transform: translateY(0);
  }


