.scrollBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    color: black; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    transform: rotate(180deg);
    z-index: 99;
}

.progress {
    width: 100%;
    height: 100%;
}

.pro-btn {
position: absolute;
width:2rem;
height:2rem;
padding:0px;
top:2px;
right:2px
}


