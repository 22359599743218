.app-img-container {
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 100;
}

@media only screen and (max-width: 576px) {
    .app-card-container {
        margin-top: 0px;
    }
    .app-card1-conrainer{
        margin-bottom: 96px;
    }
}

@media only screen and (min-width: 576px) {
    .app-card-container {
        margin-top: 0px;
    }
    .app-card1-conrainer{
        margin-bottom: 64px;
    }
}

@media only screen and (min-width: 768px) {
    .app-card-container {
        margin-top: 0px;
    }

}

@media only screen and (min-width: 992px) {
    .app-card-container {
        margin-top: 100px;
    }

}

@media only screen and (min-width: 1200px) {
    .app-card-container {
        margin-top: 100px;
    }

    .app-card1-conrainer{
        margin-top: 160px;
    }

   
}

@media only screen and (min-width: 1400px) {
    .app-card1-conrainer{
        margin-top: 160px;
    }
    .app-card-container {
        margin-top: 100px;
    }
}