.text-tranc-none p {
    text-transform: none;
}

.ele-gap-space-ex {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    align-self:center;
}

.ele-gap-space-tech {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
}


.list-con{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 24px;
}

.list-sub-con{
    gap: 4px;
}

.icon-con{
    color: #343B47;display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch
}

.list-sub-container{
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch
}

@media only screen and (max-width: 576px) {
    .port-img-right {
        justify-self: center;
    }
    .port-img-left {
        justify-self: center;
    }
    .two-col-con {
        padding-bottom:62px ;
    }
    .img-text-margin {
        margin-bottom: 80px;
    }
    .custom-mar {
        margin-top: 64px;
    }
    .image-border-rad{
        border-radius: 16px;
    }
    .video-border-rad{
        border-radius: 16px;
        aspect-ratio:16/9;
    }
}

@media only screen and (min-width: 576px) {
    .port-img-right {
        justify-self: center;
    }
    .port-img-left {
        justify-self: center;
    }
    .two-col-con {
        padding-bottom:62px ;
    }
    .img-text-margin {
        margin-bottom: 80px;
    }
    .custom-mar {
        margin-top: 64px;
    }
    .image-border-rad{
        border-radius: 36px;
    }
    .video-border-rad{
        border-radius: 36px;
        aspect-ratio:16/9;
    }
}

@media only screen and (min-width: 768px) {
    .port-img-right {
        justify-self: center;
    }
    .port-img-left {
        justify-self: center;
    }
    .two-col-con {
        padding-bottom:62px ;
    }
    .img-text-margin {
        margin-bottom: 75px;
    }
    .custom-mar {
        margin-top: 64px;
    }
    .image-border-rad{
        border-radius: 36px;
    }
    .video-border-rad{
        border-radius: 36px;
        aspect-ratio:16/9;
    }
}

@media only screen and (min-width: 992px) {
    .port-img-right {
        justify-self: right;
    }
    .port-img-left {
        justify-self: left;
    }
    .two-col-con {
        padding-bottom:78px ;
    }
    .img-text-margin {
        margin-bottom: 0px;
    }
    .custom-mar {
        margin-top: 64px;
    }
    .image-border-rad{
        border-radius: 36px;
    }
    .video-border-rad{
        border-radius: 36px;
        aspect-ratio:16/9;
    }
}


@media only screen and (min-width: 1200px) {
    .port-img-right {
        justify-self: right;
    }
    .port-img-left {
        justify-self: left;
    }
    .two-col-con {
        padding-bottom:78px ;
    }
    .img-text-margin {
        margin-bottom: 0px;
    }
    .custom-mar {
        margin-top: 0px;
    }
    .image-border-rad{
        border-radius: 36px;
    }
    .video-border-rad{
        border-radius: 36px;
        aspect-ratio:16/9;
    }
}

@media only screen and (min-width: 1400px) {
    .port-img-right {
        justify-self: right;
    }
    .port-img-left {
        justify-self: left;
    }

    .two-col-con {
        padding-bottom:102px ;
    }
    .img-text-margin {
        margin-bottom: 0px;
    }
    .custom-mar {
        margin-top: 0px;
    }
    .image-border-rad{
        border-radius: 36px;
    }
    .video-border-rad{
        border-radius: 36px;
        aspect-ratio:16/9;
    }
}