// $trans : transparent;
.card-hover-custom {
    transition: border-color .4s ease-in-out;

    &:hover {
        border-color: $primary !important;
        // background-image: linear-gradient(90deg, white, white), linear-gradient(180deg, #6BCEAE 0%, #88C57E 100%) !important;
        // background-clip: padding-box, border-box !important;
    }

}
.border-primary {
    border-color: $card-border-primary-color !important;
}

.bg-primary {
    background: $jastechCustomGreen ;
}
.bg-app-showcase {
    background: white ;
}

.zoom {
    transition: transform .5s; 
  }
  .zoom:hover {
    -ms-transform: scale(0.99); /* IE 9 */
    -webkit-transform: scale(0.99); /* Safari 3-8 */
    transform: scale(0.99); 
  }
