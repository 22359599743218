
@media only screen and (max-width: 576px) {
    .element-con{
        flex-direction: column;
        align-items: center;
        gap: 40px;
        flex: 1 0 0;
    }
    .parent-con{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        gap: 20px;
        flex-wrap: wrap;
        }
}

@media only screen and (min-width: 576px) {
    .element-con{
        min-width: 250px;
        max-width: 400px;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        flex: 1 0 0;
    }
    .parent-con{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
        gap: 32px;
        flex-wrap: wrap;
        }
}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 992px) {
  
}

@media only screen and (min-width: 1200px) {

}

@media only screen and (min-width: 1400px) {

  
}