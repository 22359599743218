
.img-position-custom1 {
    position: absolute;
    right: 0px;
    bottom: -140px;display: flex;
    width: 300px;
    height: 300px;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
   
   
   @media only screen and (max-width: 576px) {
      
        .cards-padding {
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 40px;
            padding-bottom: 0px;
        }
    }
    
    @media only screen and (min-width: 576px) {
        
        .cards-padding {
            padding-left: 80px;
            padding-right: 80px;
            padding-top: 64px;
            padding-bottom: 0px;
        }
    }
    
    @media only screen and (min-width: 768px) {
        
            .cards-padding {
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 40px;
                padding-bottom: 0px;
            }
        
    }
    
    @media only screen and (min-width: 992px) {

        .space-be {
            padding-right: 24px;
        }
        .cards-padding {
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 40px;
            padding-bottom: 0px;
        }

    }

    @media only screen and (min-width: 1200px) {
       
        .space-be {
            padding-right: 40px;
        }
        .cards-padding {
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 40px;
            padding-bottom: 0px;
        }

    }
    
    