@media only screen and (max-width: 576px) {
    .accordion-con{
        display: flex;
        padding: 40px 16px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

@media only screen and (min-width: 576px) {
    .accordion-con{
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    }
   
}

@media only screen and (min-width: 768px) {
    .accordion-con{
        display: flex;
        padding: 96px 73px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

@media only screen and (min-width: 992px) {
    .accordion-con{
        display: flex;
        padding: 96px 97px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}

@media only screen and (min-width: 1200px) {
    .accordion-con{
        display: flex;
        padding: 96px 97px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
}

@media only screen and (min-width: 1400px) {
    .accordion-con{
        display: flex;
        padding: 96px 111px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
   
}