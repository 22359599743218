// 
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change Around background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$prefix: ar-;
$font-family-sans-serif: 'Inter', sans-serif;

$white:    #fff;
$gray-100: #f6f9fc !default;
$gray-200: #edf1f5;
$gray-300: #E3E9EF;
$gray-400: #D0D5DB;
$gray-500: #99A1AC;
$gray-600: #7A818D;
$gray-700: #3A4355;
$gray-800: #343B47;
$gray-900: #24272D;

// Theme colors
$primary: #88c57e;
$secondary: $gray-200;
$info: #6539C2;
$success: #3fca90;
$warning: #edcb50;
$danger: #DA5B56;
$light: $white;
$dark: $gray-900;
$darkBlack: #000; 

//Theme jastech colors
$jastechLightBlue: #77D7EC;
$jastechSkyBlue: #48AAF0;
$jastechOrange: #FF975C;
$jastechPink: #F997DD;
$jastechGreen: #76C796;
$jastechYellow: #FFD952;
$jastechRed: #D85C5C;
$jastechTurquoise: #45D4C3;
$jastechCustomGreen: #82BF7A;
$jastechLightGreen: #A1DEBA;
$jastechLightYellow: #FFEEA6;
$jastechExtraLightYellow: #FFFDF8;
$jastechLightRed:#FAC3C5;
$jastechLightTurquoise:#59EAD8;
$jastechCustomGreenHover:#62AD58;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'darkBlack': $darkBlack,
  'gray-200' :$gray-200,
  'gray-300' :$gray-300,
  'gray-400' :$gray-400,
  'gray-500' :$gray-500,
  'gray-600' :$gray-600,
  'gray-700' :$gray-700,
  'gray-800' :$gray-800,
'jastechLightBlue': $jastechLightBlue,
'jastechSkyBlue': $jastechSkyBlue,
'jastechOrange': $jastechOrange,
'jastechPink': $jastechPink,
'jastechGreen': $jastechGreen,
'jastechYellow': $jastechYellow,
'jastechRed': $jastechRed,
'jastechTurquoise': $jastechTurquoise,
'jastechCustomGreen': $jastechCustomGreen,
'jastechLightGreen': $jastechLightGreen,
'jastechLightYellow': $jastechLightYellow,
'jastechExtraLightYellow': $jastechExtraLightYellow,
'jastechLightRed': $jastechLightRed,
'jastechLightTurquoise': $jastechLightTurquoise,
'jastechCustomGreenHover': $jastechCustomGreenHover,
'jastechBg': $gray-100,
'jastechWhite': $white ) !default;
// Buttons + Forms

$input-btn-padding-y: 12px;
$input-btn-padding-x: 28px;

$input-btn-padding-y-sm: 8px;
$input-btn-padding-x-sm: 20px;

$input-btn-padding-y-lg: 12px;
$input-btn-padding-x-lg: 32px;
$input-btn-padding-x-lg: 32px;

$custom-bg: linear-gradient(180deg, #6BCEAE 0%, #88C57E 100%
);

$btn-border-color-custom : #fff;

// custom font weight for display 3 and 4


$font-size-root: 1rem;
$font-size-base: $font-size-root ;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;
$font-weight-boldest: 900;

$font-weights: (
  1: $font-weight-semibold,
  2: $font-weight-semibold,
  3: $font-weight-boldest,
  4: $font-weight-boldest,
  5: $font-weight-semibold,
  6: $font-weight-semibold,
  7: $font-weight-normal);

$display-font-sizes: (
  1: $font-size-base * 7.5,
  2: $font-size-base * 4.5,
  3: $font-size-base * 3.75,
  4: $font-size-base * 3.5,
  5: $font-size-base * 3,
  6: $font-size-base * 2.625,
  7: $font-size-base * 2,
  8: $font-size-base,
  9: $font-size-base * 2.5,
  10: $font-size-base * 5.625
);

// custom cards 
$border-width: 0px;
$card-border-width: calc(var(--#{$prefix}border-width) * 4);
$card-border-color: var(--#{$prefix}gray-500);
$card-border-primary-color: var(--#{$prefix}white);
$card-bg: $gray-100;

// custom border-radios 
$border-radius:                             1rem ;
$border-radius-sm:                          calc(var(--#{$prefix}border-radius) * .50) ;


// breadcrumps 

$breadcrumb-font-size:                      14px !default;
$breadcrumb-font-weight:                    $font-weight-normal !default;
$breadcrumb-item-padding-x:                 .5rem !default;
$breadcrumb-color:                          var(--#{$prefix}gray-600) !default;
$breadcrumb-hover-color:                    var(--#{$prefix}gray-900) !default;
$breadcrumb-active-color:                  var(--#{$prefix}gray-900) !default;
$breadcrumb-divider-color:                  var(--#{$prefix}gray-600) !default;
$breadcrumb-divider-font-size:              1.2em !default;
$breadcrumb-divider:                        quote("\e939") !default;
$breadcrumb-divider-flipped:                quote("\e938") !default;

// accordion 

$accordion-button-active-color:             var(--#{$prefix}gray-800);