.navbar {
  --#{$prefix}navbar-stuck-bg: var(--#{$prefix}gray-100);
  --#{$prefix}navbar-toggler-color: #{$navbar-light-toggler-bar-color};
  --#{$prefix}navbar-collapse-bg: var(--#{$prefix}light);
  --#{$prefix}navbar-collapse-border-color: var(--#{$prefix}border-color);

  transition: $navbar-transition;
  
  &.navbar-stuck {
    background-color: var(--#{$prefix}navbar-stuck-bg);
  }
  .navbar-collapse {
    border-color: var(--#{$prefix}navbar-collapse-border-color);
    background-color: var(--#{$prefix}navbar-collapse-bg);
  }
  .nav-item {
    &:hover > .nav-link:not(.disabled),
    .nav-link.show:not(.disabled) {
      color: var(--#{$prefix}gray-600);
    }
    &.active > .nav-link:not(.disabled) {
      color: var(--#{$prefix}gray-800);
      font-weight:$font-weight-semibold;
    }
    &.visited >.nav-link:not(.disabled) {
      color: var(--#{$prefix}dark);
      font-weight:$font-weight-semibold;
    }
  }
  .dropdown:hover > .nav-link {
    color: var(--#{$prefix}navbar-hover-color);
  }
}
.navbar-nav {
  --#{$prefix}nav-link-font-weight: #{$font-weight-normal};
  --#{$prefix}mega-dropdown-column-width: #{$mega-dropdown-column-width};
  --#{$prefix}nav-link-hover-color: var(--#{$prefix}dark);

  padding: $spacer * .75 $spacer * 1.25;
  .nav-link {
    padding: {
      top: calc(var(--#{$prefix}nav-link-padding-y) * .75);
      bottom: calc(var(--#{$prefix}nav-link-padding-y) * .75);
    }
  }
}
